import React, { useState, useEffect, useRef, useMemo } from "react";
import { GeoJSON, Marker, Popup, useMapEvents } from "react-leaflet";
import * as L from "leaflet";
import {
  Grid,
  Typography,
  Alert,
  Tooltip,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { MapContainer, TileLayer, ImageOverlay } from "react-leaflet";
import ReactLeafletKml from "react-leaflet-kml"; // react-leaflet-kml must be loaded AFTER react-leaflet
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import LeafletgeoSearch from "./LeafletgeoSearch";
import { Button } from "antd";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import ContactForm from "../contact/ContactForm";
import Tour from "reactour";
import Title from "../utils/Title";
import { useNavigate } from "react-router-dom";
import "./font.css";
import parse from "html-react-parser";
import DateCard from "./DateCards";
import { useDispatch, useSelector } from "react-redux";
import SplitPane, { Pane } from "split-pane-react";
import "split-pane-react/esm/themes/default.css";
import LegendComponent from "../utils/MapLegend";
import Control from "react-leaflet-custom-control";
import ESS_LOGO from "../../public/images/ESS-06.png";
import LayersIcon from '@mui/icons-material/Layers';
import AdjustIcon from '@mui/icons-material/Adjust';
import {
  CancelOutlined,
  CompareOutlined,
  Upload,
  PowerSettingsNew,
  Edit,
  EditAttributesOutlined,
  EditLocation,
  EditNoteOutlined,
  Draw,
  DrawOutlined,
  UploadFileOutlined,
  UploadOutlined,
  PowerSettingsNewOutlined,
  Splitscreen,
  HorizontalSplitSharp,
  CallSplitSharp,
  SettingsApplicationsSharp,
  VerticalSplitSharp,
  AddLocationAltOutlined,
  PlaylistAdd,
  SplitscreenOutlined,
} from "@mui/icons-material";
import Modal from "@mui/material/Modal";
import FileUpload from "react-material-file-upload";
import "leaflet/dist/leaflet.css";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import { useLeafletContext } from "@react-leaflet/core";
import moment from "moment";
import { Loader } from "../utils/Loader";
import axios from "axios";
import { MSAVI, NDVIColor } from "../utils/constants";
import { Oval } from "react-loader-spinner";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SLegendComponent from "../utils/SecondaryMapLegend";
import SDateCard from "./SecondaryDateCards";
import KMLLayer from "./KMLLayer";
import LocationFound from "./FoundLocation";
import CWR from "./CWR";
import UpdateField from "./UpdateField";
import { getIPandLocation } from "../utils/Functions";
import CustomLoader from "../utils/CustomLoader";
import toGeoJson from "@mapbox/togeojson";
import { useRouter } from "../routes/hooks";
import { useAuth } from "../hooks/useAuth";
import ZoneNumberModal from "./ZoneNumberModal";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import PolylineIcon from "@mui/icons-material/Polyline";
import CloseIcon from "@mui/icons-material/Close";
import DRAW_FIELD from "../../public/images/ADD_FIELD.svg";
import UPLOAD_FILE from "../../public/images/UPLOAD.svg";
import SPLIT from "../../public/images/split.svg";
import Farms from "./Farm";
import WeatherAnalytics from "./WeatherAnalytics.js";
import ScoutingUpdate from "./AddScouting.js";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css"; 
import {Howl, Howler} from 'howler';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { motion, useAnimation } from 'framer-motion';
import BotView from "./BotView.js";
import { Select } from "@mui/material";
import cropsArray from "./cropsArray.js";
 
function MapView(centerPosition) {
  const steps = [
    {
      selector: ".leaflet-geosearch-button",
      content: parse(
        `<span style="font-family:'Jameel Noori Nastaleeq Regular'; font-size:20px"}}>Geo locate Farm if you have Lat/Lng or with district name.</br>کوارڈینیٹس یا ضلع / تحصیل کے نام کا استعمال کرکے اپنا کھیت تلاش کریں</span>`
      ),
    },
    {
      selector: ".leaflet-pm-toolbar",
      content: parse(
        `<span style="font-family:'Jameel Noori Nastaleeq Regular'; font-size:20px"}}>Draw map boundry using any of these tool<br/>اپنے فارم کی حدود لگائیں</span>`
      ),
    },
    {
      selector: ".leaflet-pm-icon-polygon",
      content: parse(
        `<span style="font-family:'Jameel Noori Nastaleeq Regular'; font-size:20px"}}>Recommended is to use polygon shape to draw exact boundry<br/>یہ ٹول نشاندہی کے لئے بہترین ہے</span>`
      ),
    },
    {
      selector: ".step4",
      content: parse(
        `<span style="font-family:'Jameel Noori Nastaleeq Regular'; font-size:20px"}}>After completing boundry marking, fill necessary details with your message and you will be contacted shortly<br/>نشاندہی مکمل ہونے کے بعد، ضروری تفصیلات کے ساتھ آپ فارم بھریں اور آپ سے جلدی رابطہ کیا جائے گا۔</span>`
      ),
    },
  ];
  const { logoutAuth } = useAuth();
  const router = useRouter();
  const [kmlData, setKMLData] = useState(null);
  const [isTourOpen, setIsTourOpen] = useState(true);
  const [scoutingEnabled, setScoutingEnabled] = useState(false);
  const [indices, setIndices] = React.useState("NDVI");
  const [secondaryIndices, setSecondaryIndices] = React.useState("NDVI");
  const [sizes, setSizes] = useState([100, "5%", "auto"]);
  const [map, setMap] = useState(null);
  const [SentimageURL, setSentimageURL] = useState("");
  const [SentimageURLSec, setSentimageURLSec] = useState("");
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [farmLayer, setFarmLayer] = useState("");
  const [viewID, setViewID] = useState("");
  const [cropperRefValue, setCropperRef] = useState("");
  const [colorMap, setColorMap] = useState("");
  const dispatch = useDispatch();
  const currentDate = moment();
  const newDate = currentDate.subtract(6, "months");
  const formattedDate = newDate.format("YYYY-MM-DD");
  const [isMarkerMode, setIsMarkerMode] = useState(false);
  const [startOfYear, setStartOfYear] = useState(formattedDate);
  const [tillDate, setTillDate] = useState(moment().format("YYYY-MM-DD"));
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(1);
  const [boundingBox, setBoundingBox] = useState("");
  const [bounds, setBounds] = useState(null);
  const [defaultIndex, setDefaultIndex] = useState("NDVI");
  const [loading, setLoading] = useState(false);
  const [farmArea, setFarmArea] = useState("");
  const [farmID, setFarmID] = useState("");
  const [statsID, setStatsID] = useState("");
  const [dateArray, setDateArray] = useState([]);
  const [centerCoordinates, setCenterCoodinates] = useState([]);
  const [activeCompare, setActiveCompare] = useState(false);
  const [showCwr, setShowCwr] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isHidden, setHidden] = useState(false);
   

  const [showBotButton, setShowBotButton] = useState(false);
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const [showAnalytics, setShowAnalytics] = useState(false); 
  const [showUpdateFieldModal, setShowUpdateFieldModal] = useState(false);
  const [showScoutingModal, setShowScoutingModal] = useState(false); 
  const [showZoneNumberModal, setShowZoneNumberModal] = useState(false);
  const [showZoneNumberModalSec, setShowZoneNumberModalSec] = useState(false);
  const [numberOfZones, setNumberOfZones] = useState(3);
  const [numberOfZonesSec, setNumberOfZonesSec] = useState(3);
  const [fieldData, setFieldData] = useState(null);
  const [position, setPosition] = useState(null);
  const [zoningDrawing, setZoningDrawing] = useState(null);
  const [zoningDrawingSec, setZoningDrawingSec] = useState(null);
  const [legendComponentPosition, setLegendComponentPosition] = useState("54%");
  const [marker, setMarker] = useState(null);
  const [showForms, setShowForms] = useState(false);
  const [legendComponentPositionSec, setLegendComponentPositionSec] =
    useState("50%");
  const fileInputRef = React.useRef(null);
  const layoutCSS = {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderLeft: "5px solid #24242487",
  };
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [open, setOpen] = useState(false);
 

  const fetchCenter = async () => {

    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
    function success(pos) {
     // console.log("PIS",pos)
      var crd = pos.coords;
     // console.log("Your current position is:");
     // console.log(`Latitude : ${crd.latitude}`);
     // console.log(`Longitude: ${crd.longitude}`);
  
      const center = [crd.latitude, crd.longitude];
 
      setPosition(center);
    }
  
    function errors(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }


      if (navigator.geolocation) {
        navigator.permissions
          .query({ name: "geolocation" })
          .then(function (result) {
             
            if (result.state === "granted") {
              //If granted then you can directly call your function here
           return navigator.geolocation.getCurrentPosition(success, errors, options);
            } else if (result.state === "prompt") {
              //If prompt then the user will be asked to give permission
              return  navigator.geolocation.getCurrentPosition(success, errors, options);
            } else if (result.state === "denied") { 
                
              const center = [33.6093072 ,73.0995412];
              setPosition(center);

            }
          });
      } else {
        const center = [33.6093072 ,73.0995412];
        setPosition(center);
      }

  };


  useEffect(() => {

    if(position == null){
      fetchCenter()

    }
   
    

    
   

    
    
  }, []);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const floatingLayerStyles = {
    position: "absolute",
    bottom: "2px",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 1000,
  };

  function handleVegetationCase(newIndex, intValue) {
    const data = {
      farmID: farmID,
      indice: newIndex === "NDMI" ? "NDVI" : newIndex,
      zone_quantity: intValue,
    };

    axios
      .post("https://escan-systems.com/api/vegetation", data)
      .then((response) => {
        _getTaskResultForVegetation(response.data.request_url);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function handleVegetationCaseSec(newIndex, intValue) {
    const data = {
      farmID: farmID,
      indice: newIndex === "NDMI" ? "NDVI" : newIndex,
      zone_quantity: intValue,
    };

    axios
      .post("https://escan-systems.com/api/vegetation", data)
      .then((response) => {
        _getTaskResultForVegetationSec(response.data.request_url);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const _onZoningChange = async (event) => {
   

    setShowZoneNumberModal(true);

    // const selectedValue = event.target.value;
    // console.log(selectedValue)
    // switch (selectedValue) {
    //   case "productivity": {
    //     return;
    //   }

    //   case "vegetation": {
    //     setShowZoneNumberModal(true);
    //     break;
    //   }
    //   default:
    //     setZoningDrawing(null);
    //     break;
    // }
  };

  const _onZoningChangeSec = async (event) => {
    setShowZoneNumberModalSec(true);

    // const selectedValue = event.target.value;
    // switch (selectedValue) {
    //   case "productivity": {
    //     return;
    //   }

    //   case "vegetation": {
    //     setShowZoneNumberModalSec(true);
    //     break;
    //   }
    //   default:
    //     setZoningDrawingSec(null);
    //     break;
    // }
  };

  const convertToGeoJSON = (data) => {
    const zonesFeatures = data.zones.map((zone, index) => {
      const zoneKey = `zone_${index + 1}`;
      const coordinates = zone[zoneKey].geometry.coordinates;

      return {
        type: "Feature",
        properties: {
          zone_id: zoneKey,
          zone_area: zone[zoneKey].zone_area,
          zone_p: zone[zoneKey].zone_p,
          fertilizer: zone[zoneKey].fertilizer,
          kmean: zone[zoneKey].kmean[0],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: coordinates,
        },
      };
    });

    const geoJSON = {
      type: "FeatureCollection",
      features: zonesFeatures,
    };

    return geoJSON;
  };

  const convertToGeoJSONForProductivity = (data) => {
    const zonesFeatures = data.zones.map((zone, index) => {
      const zoneKey = `zone_${index}`;
      const coordinates = zone[zoneKey].geometry.coordinates;

      return {
        type: "Feature",
        properties: {
          zone_id: zoneKey,
          zone_area: zone[zoneKey].zone_area,
          zone_p: zone[zoneKey].zone_p,
          fertilizer: zone[zoneKey].fertilizer,
          // kmean: zone[zoneKey].kmean[0],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: coordinates,
        },
      };
    });

    const geoJSON = {
      type: "FeatureCollection",
      features: zonesFeatures,
    };

    return geoJSON;
  };

  const __getFeatureStyle = (feature) => {
    const kmean = feature.properties.kmean;
    let color = "";
    if (kmean >= 0.8) {
      // Very good: Green
      color = "rgb(0, 128, 0)";
    } else if (kmean >= 0.6) {
      // Good: Light Green
      color = "rgb(144, 238, 144)";
    } else if (kmean >= 0.4) {
      // Normal: Yellow
      color = "rgb(255, 255, 0)";
    } else if (kmean >= 0.2) {
      // Bad: Red
      color = "rgb(255, 0, 0)";
    } else {
      // Worst: Purple
      color = "rgb(128, 0, 128)";
    }
    return { fillColor: color, color: "black", weight: 0, fillOpacity: 1 };
  };

  const __getFeatureStyleSec = (feature) => {
    const kmean = feature.properties.kmean;
    let color = "";
    if (kmean >= 0.8) {
      // Very good: Green
      color = "rgb(0, 128, 0)";
    } else if (kmean >= 0.6) {
      // Good: Light Green
      color = "rgb(144, 238, 144)";
    } else if (kmean >= 0.4) {
      // Normal: Yellow
      color = "rgb(255, 255, 0)";
    } else if (kmean >= 0.2) {
      // Bad: Red
      color = "rgb(255, 0, 0)";
    } else {
      // Worst: Purple
      color = "rgb(128, 0, 128)";
    }
    return { fillColor: color, color: "black", weight: 0, fillOpacity: 1 };
  };

  const __generateColor = (feature) => {
    const kmean = feature.properties.kmean;

    if (kmean >= 0.8) {
      // Very good: Green
      return "rgb(0, 128, 0)";
    } else if (kmean >= 0.6) {
      // Good: Light Green
      return "rgb(144, 238, 144)";
    } else if (kmean >= 0.4) {
      // Normal: Yellow
      return "rgb(255, 255, 0)";
    } else if (kmean >= 0.2) {
      // Bad: Red
      return "rgb(255, 0, 0)";
    } else {
      // Worst: Purple
      return "rgb(128, 0, 128)";
    }
  };

  const getFeatureStyle = (feature) => {
    const minKmean = Math.min(
      ...zoningDrawing.features.map((f) => f.properties.kmean)
    );
    const maxKmean = Math.max(
      ...zoningDrawing.features.map((f) => f.properties.kmean)
    );
    // const startColor = [86, 60, 92];
    // const endColor = [144, 238, 144];
    const startColor = [128, 0, 128];
    const endColor = [0, 255, 0];
    const normalizedValue =
      (feature.properties.kmean - minKmean) / (maxKmean - minKmean);
    const interpolatedColor = startColor.map((channel, index) => {
      const range = endColor[index] - channel;
      return Math.round(channel + range * normalizedValue);
    });
    const color = `rgb(${interpolatedColor.join(",")})`;
    return { fillColor: color, color: "black", weight: 0, fillOpacity: 1 };
  };

  const getFeatureStyleSec = (feature) => {
    const minKmean = Math.min(
      ...zoningDrawing.features.map((f) => f.properties.kmean)
    );
    const maxKmean = Math.max(
      ...zoningDrawing.features.map((f) => f.properties.kmean)
    );
    // const startColor = [86, 60, 92];
    // const endColor = [144, 238, 144];

    const startColor = [128, 0, 128];
    const endColor = [0, 255, 0];

    const normalizedValue =
      (feature.properties.kmean - minKmean) / (maxKmean - minKmean);
    const interpolatedColor = startColor.map((channel, index) => {
      const range = endColor[index] - channel;
      return Math.round(channel + range * normalizedValue);
    });
    const color = `rgb(${interpolatedColor.join(",")})`;
    return { fillColor: color, color: "black", weight: 0, fillOpacity: 1 };
  };

  const generateColor = (myObject, feature) => {
    const minKmean = Math.min(
      ...myObject.features.map((f) => f.properties.kmean)
    );
    const maxKmean = Math.max(
      ...myObject.features.map((f) => f.properties.kmean)
    );
    // const startColor = [86, 60, 92];
    // const endColor = [144, 238, 144];

    const startColor = [128, 0, 128];
    const endColor = [0, 255, 0];

    //
    const normalizedValue =
      (feature.properties.kmean - minKmean) / (maxKmean - minKmean);
    const interpolatedColor = startColor.map((channel, index) => {
      const range = endColor[index] - channel;
      return Math.round(channel + range * normalizedValue);
    });
    const color = `rgb(${interpolatedColor.join(",")})`;
    return color;
  };


  //const rgb = (r, g, b) => `rgb(${r}, ${g}, ${b})`;
  const _generateMap = async (data) => {
    
    try {
      const myObject = convertToGeoJSON(data);
      myObject.features.map((features, outerIndex) => {
        features.geometry.coordinates.map((coord, innerIndex) => {
          myObject.features[outerIndex].geometry.coordinates[innerIndex] = [
            coord,
          ];
        });
      });
      myObject.features.map((feature, outerIndex) => {
        console.log("INNER",myObject.features[outerIndex])
        const generatedColor = generateColor(myObject,feature);
        myObject.features[outerIndex].properties.color = generatedColor;
      });
      setZoningDrawing(myObject);
    } catch (error) {
      console.error("Error making the POST request", error);
    }
  };

  const _generateMapSec = async (data) => {
    try {
      const myObject = convertToGeoJSON(data);
      myObject.features.map((features, outerIndex) => {
        features.geometry.coordinates.map((coord, innerIndex) => {
          myObject.features[outerIndex].geometry.coordinates[innerIndex] = [
            coord,
          ];
        });
      });
      myObject.features.map((feature, outerIndex) => {
        const generatedColor = generateColor(myObject,feature);
        myObject.features[outerIndex].properties.color = generatedColor;
      });
      setZoningDrawingSec(myObject);
    } catch (error) {
      console.error("Error making the POST request", error);
    }
  };

  const _getTaskResultForVegetation = async (taskId) => {
    try {
      const data = {
        taskId: taskId,
      };

      // Make a POST request to the eleventhTestingAPI endpoint
      const response = await axios.post(
        "https://escan-systems.com/api/getTaskResultForVegetation",
        data
      );

      // Handle the response from the server
      if (response.data.status === "pending") {
        // If the task status is pending, retry after 10 seconds
        setTimeout(() => {
          _getTaskResultForVegetation(taskId);
        }, 10000);
      } else {
        // If the task is completed, generate the map
        _generateMap(response.data);

        // Show a toast message
      }
      toast(
        "Farm Uploaded, Map will take some time to load. View will be updated as soon as it is available",
        {
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "dark",
          transition: Bounce,
        }
      );
    } catch (error) {
      console.error("Error making the POST request", error);
    }
  };

  const _getTaskResultForVegetationSec = async (taskId) => {
    try {
      const data = {
        taskId: taskId,
      };

      // Make a POST request to the eleventhTestingAPI endpoint
      const response = await axios.post(
        "https://escan-systems.com/api/getTaskResultForVegetation",
        data
      );

      // Handle the response from the server
      if (response.data.status === "pending") {
        // If the task status is pending, retry after 10 seconds
        setTimeout(() => {
          _getTaskResultForVegetationSec(taskId);
        }, 10000);
      } else {
        // If the task is completed, generate the map
        _generateMapSec(response.data);

        // Show a toast message
      }
      toast(
        "Farm Uploaded, Map will take some time to load. View will be updated as soon as it is available",
        {
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "dark",
          transition: Bounce,
        }
      );
    } catch (error) {
      console.error("Error making the POST request", error);
    }
  };

  const handleChange = async (index) => {
    setZoningDrawing(null)
    const newIndex = index;
    let parts;
    // setSentimageURL(SentimageURL.replace(indices, newIndex))
    if (zoningDrawing !== null) {
      handleVegetationCase(newIndex, numberOfZones);
    }
    if (indices == "MSAVI" ||  indices == MSAVI) {
      parts = SentimageURL.split(MSAVI);
    } else {
      parts = SentimageURL.split(indices);
    }
    const colorModule = await import(`../utils/constants`); 
    const oldColor = colorModule[indices + "ColorMap"]; 
    const newColor = colorModule[newIndex + "ColorMap"];  
    
    const indexLevel = colorModule[indices + "Level"];
    const newIndexLevel =  colorModule[newIndex + "Level"]; 
    // createColourMap(farmLayer, color)
   //   .then((data) => {
     
     
       const Newlevels = parts[1].split("&COLORMAP="+oldColor+"&MIN_MAX="+indexLevel).join("&COLORMAP="+newColor+"&MIN_MAX="+newIndexLevel);
       // console.log("JADU",Newlevels)
        const modifiedUrl = parts.join(newIndex);
          if (newIndex == "MSAVI") {
         
          setSentimageURL(parts[0] + MSAVI + Newlevels);
        } else {
            
         setSentimageURL(parts[0] + newIndex + Newlevels);
       }
      //  getStats(farmLayer)
        setIndices(newIndex);
    //  })
    //  .catch((error) => {
    //    console.error("Error in useEffect:", error);
    //  });
     // Call to update Graph
      //getStats (farmLayer)
  };

 

  const handleSecondaryIndicesChange = async (index) => {

    setZoningDrawingSec(null)
     const newIndex = index;
    let parts;
    // setSentimageURL(SentimageURL.replace(indices, newIndex))
    if (zoningDrawingSec !== null) {
      handleVegetationCase(newIndex, numberOfZones);
    }
    if (secondaryIndices == "MSAVI" ||  secondaryIndices == MSAVI) {
      parts = SentimageURLSec.split(MSAVI);
    } else {
      parts = SentimageURLSec.split(secondaryIndices);
    }
    const colorModule = await import(`../utils/constants`); 
    const oldColor = colorModule[secondaryIndices + "ColorMap"]; 
    const newColor = colorModule[newIndex + "ColorMap"];  
    
    const indexLevel = colorModule[secondaryIndices + "Level"];
    const newIndexLevel =  colorModule[newIndex + "Level"]; 
    // createColourMap(farmLayer, color)
   //   .then((data) => {
     
     
       const Newlevels = parts[1].split("&COLORMAP="+oldColor+"&MIN_MAX="+indexLevel).join("&COLORMAP="+newColor+"&MIN_MAX="+newIndexLevel);
       // console.log("JADU",Newlevels)
        const modifiedUrl = parts.join(newIndex);
          if (newIndex == "MSAVI") {
         
            setSentimageURLSec(parts[0] + MSAVI + Newlevels);
        } else {
            
          setSentimageURLSec(parts[0] + newIndex + Newlevels);
       }
      //  getStats(farmLayer)
      setSecondaryIndices(newIndex);
    //  })
    //  .catch((error) => {
    //    console.error("Error in useEffect:", error);
    //  });
     // Call to update Graph
      //getStats (farmLayer)

    // const newIndex = index;
    // let parts;
    // if (zoningDrawingSec !== null) {
    //   handleVegetationCaseSec(newIndex, numberOfZonesSec);
    // }
    // if (secondaryIndices == "MSAVI") {
    //   parts = SentimageURLSec.split(MSAVI);
    // } else {
    //   parts = SentimageURLSec.split(secondaryIndices);
    // }

     

    // createColourMap(farmLayer, color)
    //   .then((data) => {
    //     const colormap = parts[1].split(colorMap).join(data.id);
    //     const modifiedUrl = parts.join(newIndex);
    //     if (newIndex == "MSAVI") {
    //       setSentimageURLSec(parts[0] + MSAVI + colormap);
    //     } else {
    //       setSentimageURLSec(parts[0] + newIndex + colormap);
    //     }

    //     setSecondaryIndices(newIndex);
    //   })
    //   .catch((error) => {
    //     console.error("Error in useEffect:", error);
    //   });
  };

  const mapRef = useRef();

  const getStats = async (layer) => {
    let data = JSON.stringify({
      type: "mt_stats",
      params: {
        bm_type: indices,
        date_start: startOfYear,
        date_end: tillDate,
        geometry: layer.features[0].geometry,
        reference: "ref_20200924-00-00",
        sensors: ["sentinel2"],
      },
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://escan-systems.com/api/getStats",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios
      .request(config)
      .then((response) => {
        setStatsID(response.data.task_id);
        setTimeout(() => {
          getTaskResult(response.data.task_id)
            .then((data) => {
              //
            })
            .catch((error) => {
              // Handle errors here
              console.error("Error in useEffect:", error);
            });
        }, 10000);
      })
      .catch((error) => {});
  };

  const getTaskResult = async (taskID) => {
    const dateArray = [];
    let config = {
      method: "get", 
      maxBodyLength: Infinity,
      url: `https://escan-systems.com/api/getTaskResult/${taskID}`,
      headers: {},
    };

    await axios
      .request(config)
      .then((response) => {
        if ("status" in response.data) {
          setTimeout(() => {
            getTaskResult(taskID);
          }, 10000);
        } else {
          response.data.result.forEach(async (view) => {
 
            dateArray.push({
              date: moment(view.date).format("MMM DD, YYYY"),
              view: view.view_id,
              cloud: view.cloud,
              sceneID: view.scene_id,
              average: view.average,
              max: view.max,
              median: view.median,
              min: view.min,
              p10: view.p10,
              variance: view.variance,
              p90: view.p90,
              q1: view.q1,
              q3: view.q3,
              std: view.std,
            });
          });
          dateArray.sort((a, b) => {
            // Parse the date strings back to date objects
            const dateA = moment(a.date, "MMM DD, YYYY").toDate();
            const dateB = moment(b.date, "MMM DD, YYYY").toDate();

            // Compare the date objects
            return dateA - dateB;
          });
          setDateArray(dateArray);

           setViewID(dateArray[dateArray.length-1].view);
           localStorage.setItem("view",dateArray[dateArray.length-1].view)

          if( setLoading ) {
            setLoading(false);
          }
        }

        //
      })
      .catch((error) => {
        //  console.log("WHAT IS THIS",dateArray)
      });
  };

  const getClusterRef = async (layer) => {
    let data = JSON.stringify({
      calibrate: 1,
      clusters_no: 2,
      geometry: layer.features[0].geometry,
      method: "kmeans",
      min_area: 2000,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api-connect.eos.com/api/render/clustering_options/?api_key=apk.e7c0c70a8cf524a8324cf7831d2ac3ff26cb651a61a3fcef28967f1c062ed3de",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {})
      .catch((error) => {});
  };
  const registerFarm = async (layer) => {
    setLoading(true);
    // console.log(layer,layer.features[0].geometry)
    let data = JSON.stringify({
      type: "Feature",
      properties: {
        name: "Field_5001",
        group: "GU10-ST",
        years_data: [
          {
            crop_type: "Almonds",
            year: 2019,
            sowing_date: "2019-06-01",
          },
        ],
      },
      geometry: layer.features[0].geometry,
    });
    let token = localStorage.getItem("token");
    token = token.replace(/^"(.*)"$/, "$1");
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://escan-systems.com/api/registerFarm",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    await axios
      .request(config)
      .then((response) => {
        setLoading(false);
        setFarmID(response.data.id);
        setFarmArea(response.data.area);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getViews = async (
    layer,
    limit,
    page,
    startOfYear,
    tilleDate,
    box,
    firstLoad
  ) => { 
    setFarmLayer(layer)
    const boundry =
      box.toString().split(",")[0] +
      ";" +
      box.toString().split(",")[2] +
      ";4326/" +
      box.toString().split(",")[1] +
      ";" +
      box.toString().split(",")[3] +
      ";4326";
    
      if(typeof(box) == 'object'){

        
        const boundryb =
        box.toBBoxString().toString().split(",")[0] +
      ";" +
      box.toBBoxString().toString().split(",")[2] +
      ";4326/" +
      box.toBBoxString().toString().split(",")[1] +
      ";" +
      box.toBBoxString().toString().split(",")[3] +
      ";4326";
        localStorage.setItem("boundry",JSON.stringify(boundryb))
        
      }else{
   
        localStorage.setItem("boundry",JSON.stringify(boundry))
      }

    let SendInfo = {
      fields: ["sceneID", "cloudCoverage"],
      search: {
        date: {
          from: startOfYear,
          to: tilleDate,
        },
        cloudCoverage: {
          from: 1,
          to: 50,
        },
        shape: layer.features[0].geometry,
      },
      sort: {
        date: "desc",
      },
    };

    // console.log("BEFFORE",SendInfo)
    // console.log("KML",leafletContainer.pm.getGeomanLayers(true).toGeoJSON().features[0].geometry)

    axios({
      method: "post",
      url: "https://gate.eos.com/api/lms/search/v2/sentinel2?api_key=apk.e7c0c70a8cf524a8324cf7831d2ac3ff26cb651a61a3fcef28967f1c062ed3de",
      data: SendInfo,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    })
      .then(async function (response) {
        const dateArray = [];
        if (firstLoad) {
          setViewID(response.data.results[0].view_id);
        }
        response.data.results.forEach(async (view) => {
          const datePart = view.view_id.match(/\d{4}\/\d{1,2}\/\d{1,2}/);

          const parsedDate = new Date(datePart);

          const options = { day: "numeric", month: "short", year: "numeric" };

          const formattedDate = parsedDate.toLocaleDateString("en-US", options);

          dateArray.push({
            date: formattedDate,
            view: view.view_id,
            cloud: view.cloudCoverage,
            sceneID: view.sceneID,
            tiles: view.tms,
          });
        });
        getCropper(layer)
          .then(async (outer_data) => {
           localStorage.setItem("cropper",outer_data.cropper_ref)
            const colorModule = await import(`../utils/constants`);
            const color = colorModule[indices + "ColorMap"]; 
            const indexLevel = colorModule[indices + "Level"]; 
            // console.log(color,indexLevel);
            setCropperRef(outer_data.cropper_ref); 
            //  .then((data) => {
                //  //createColourMap(layer, color) response.data.results[0].view_id
                setColorMap(color);
                if (firstLoad) {
                  const url =
                    "https://gate.eos.com/api/render/" +
                     viewID +
                    "/" +
                    defaultIndex +
                    "/13/" +
                    boundry +
                    "?TILE_SIZE=400,400&cropper_ref=" +
                    outer_data.cropper_ref +
                    "&CALIBRATE=1&COLORMAP=" +
                    color +
                    "&MIN_MAX="+
                    indexLevel+"&MASKING=CLOUD&MASK_COLOR=cccccc&api_key=apk.e7c0c70a8cf524a8324cf7831d2ac3ff26cb651a61a3fcef28967f1c062ed3de";
                  //console.log("SENTINELURL", url);
                  setSentimageURL(url);
                  setSentimageURLSec(url);
                }
            //  })
             // .catch((error) => {
              //  console.error("Error in useEffect:", error);
             // });
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error in useEffect:", error);
          });
      })
      .catch(function (error) {
        // Handle errors here
        console.error("Error:", error);
      });
  };

  const getCropper = async (layer) => {
    var SendInfo = layer.features[0];
    try {
      const response = await axios.post(
        "https://escan-systems.com/api/getCropper",
        SendInfo,
        {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      );
      console.log(response.data); // Assuming you want to log the response data
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      // Handle error appropriately
    }
  };

  const createColourMap = async (layer, colorIndex) => {
    try {
      const response = await axios.post(
        "https://escan-systems.com/api/createColourMap",
        JSON.stringify(colorIndex),
        {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      );
      // console.log("createColourMap",response.data)
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
      throw error;
    }
  };

  const MapController = () => {
    const context = useLeafletContext();

    useEffect(() => {
      const leafletContainer = context.layerContainer || context.map;
      leafletContainer.pm.addControls({
        position: "topleft",
        drawMarker: false,
        drawCircle: false,
        drawCircleMarker: false,
        drawText: false,
        drawPolyline: false,
        drawRectangle: false,
        cutLayers: false,
        editable: false,
        cutPolygon: false,
        editPolygon: false,
        dragLayers: false,
        allowSelfIntersection: false,
      });

      const divElement = document.getElementsByClassName("button-container  ");

      for (const element of divElement) {
        if (
          element.title === "Drag Layers" ||
          element.title === "Rotate Layers"
        ) {
          element.style.display = "none";
        }

        if (element.title === "Draw Polygons") {
          element.title = "Add or Draw Farm / Field";
          element.style.display = "none";
        }

        // if (element.title === "Draw Marker") {
        //   element.title = "Drop a pin on field to add task";
        //   //element.style.display = "none";
        //   var tooltip = document.getElementById('leaflet-tooltip-486');

         
        //   if (tooltip) {
            
        //     tooltip.innerText = "Drop a pin on field to add scouting task";
        //   }

        //   var markerPaneElements = document.querySelectorAll('.leaflet-marker-draggable');

        //    markerPaneElements.forEach(function(element) {
        //     element.style.display = 'none';
        //   });

        // }


        if (element.title === "Remove Layers") {
          element.title = "Remove Farm / Field";
          element.style.display = "none";
        }
      }

      leafletContainer.pm.setGlobalOptions({
        pmIgnore: false,
        allowSelfIntersection: false,
      });

      const handleCreate = (e) => {
        if (e.layer && e.layer.pm) {
          const shape = e;
          shape.layer.pm.disable();
          const layersBounds = leafletContainer.pm
            .getGeomanLayers(true)
            .getBounds();
          setBounds([
            [layersBounds._southWest.lat, layersBounds._southWest.lng],
            [layersBounds._northEast.lat, layersBounds._northEast.lng],
          ]);
          setBoundingBox(layersBounds.toBBoxString());
          setFarmLayer(leafletContainer.pm.getGeomanLayers(true).toGeoJSON());
          setCenterCoodinates(layersBounds.getCenter());
          console.log(registerFarm(leafletContainer.pm.getGeomanLayers(true).toGeoJSON()))
          getStats(leafletContainer.pm.getGeomanLayers(true).toGeoJSON());
         
          getViews(
            leafletContainer.pm.getGeomanLayers(true).toGeoJSON(),
            limit,
            page,
            startOfYear,
            tillDate,
            layersBounds.toBBoxString(),
            true
          );

          shape.layer.pm.enable({
            allowSelfIntersection: false,
          });
          // alert('Shape creation completed!');
          shape.layer.pm.disable();
        }
      };

      leafletContainer.on("pm:create", handleCreate);

      return () => {
        leafletContainer.pm.removeControls();
        leafletContainer.pm.setGlobalOptions({ pmIgnore: true });
        leafletContainer.off("pm:create", handleCreate);
      };
    }, []);

    return null;
  };

  const getViewIDfromDate = (viewID) => {
    
    setLoading(true);
    setZoningDrawing(null)
    setTimeout(() => {
      const parts = SentimageURL.split("render");
      console.log("viewID",parts)
      if (indices == "MSAVI") {
        const part1 = parts[1].split(MSAVI);
        const url = parts[0] + "render/" + viewID + "/" + MSAVI + part1[1];
        console.log("url",url,viewID)
        setSentimageURL(url);
  
        setLoading(false);
      } else {
        const part1 = parts[1].split(indices);
        const url = parts[0] + "render/" + viewID + "/" + indices + part1[1];
        console.log("url",url,viewID)
        setSentimageURL(url);
  
        setLoading(false);
      }
 
  
    }, 2000);
  
  };

  const getSViewIDfromDate = (viewID) => {
    setZoningDrawing(null)
    setLoading(true);

    // setTimeout(() => {
    //   const parts = SentimageURL.split("render");
    //   const part1 = parts[1].split(indices);
    //   const url = parts[0] + "render/" + viewID + "/" + indices + part1[1];
    //   setSentimageURLSec(url);
    //   setLoading(false);
    // }, 2000);

    setTimeout(() => {
      const parts = SentimageURLSec.split("render");
      console.log("viewID",parts)
      if (secondaryIndices == "MSAVI") {
        const part1 = parts[1].split(MSAVI);
        const url = parts[0] + "render/" + viewID + "/" + MSAVI + part1[1];
        console.log("url",url,viewID)
        setSentimageURLSec(url);
  
        setLoading(false);
      } else {
        const part1 = parts[1].split(secondaryIndices);
        const url = parts[0] + "render/" + viewID + "/" + secondaryIndices + part1[1];
        console.log("url",url,viewID)
        setSentimageURLSec(url);
  
        setLoading(false);
      }
 
  
    }, 2000);


  };

  const updateCwrState = () => {
    //  setShowCwr(!showCwr)
    setShowCwr((prev) => !prev);
  };


  const updateAnalyticsState = () => {
    //  setShowCwr(!showCwr)
    
    setShowAnalytics((prev) => !prev);
    
  };

  const dateRangeUpdate = (range) => {
    try {

      const startDate = moment(range[0].startDate).format("YYYY-MM-DD");
      const endDate = moment(range[0].endDate).format("YYYY-MM-DD");
      setStartOfYear(startDate);
      setTillDate(endDate);
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  };
  useEffect(() => {
    if (startOfYear != formattedDate) {
     /// setLoading(true);
      getViews(
        farmLayer,
        limit,
        page,
        startOfYear,
        tillDate,
        boundingBox,
        false
      );
      console.log(farmLayer)
   
      // console.log("AYA IDHR")
      if(!showBotButton){
        var sound = new Howl({
          src: ['https://escan-systems.com/popup.mp3'],
          autoplay: true,
          loop: false,
          volume: 1, html5: true,
          onend: function () {
            setShowBotButton(true)
            //console.log('Finished!');
          }
        });
        // console.log(sound, "HERE AYA?")
        sound.play();
    
       // setShowBotButton(true);
      }
     
    }

    

  }, [startOfYear, tillDate]);

  // const onFileChange = (file) => {
  //   if (file.length > 0) {
  //     const name = file[0].name;
  //     const ext = name.split(".")[1];

  //     if (ext === "kml") {
  //       setShowUploadModal(false);
  //       setLoading(true);
  //       const formData = new FormData();
  //       formData.append("file", file[0]);
  //       const uploadUrl = "http://localhost:8080/uploadFile";
  //       axios
  //         .post(uploadUrl, formData, {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //           },
  //         })
  //         .then((response) => {
  //           const fileURL = `https://escan-systems.com/backend/uploadedKML/20240421075318204Z_204.kml`;
  //           setKMLData(fileURL);
  //           setShowUploadModal(false);
  //           setLoading(false);
  //         })
  //         .catch((error) => {
  //           console.error("Error uploading file", error);
  //         });
  //     } else if (ext === "shp") {
  //     } else if (ext === "shx") {
  //     } else if (ext === "kmz") {
  //     } else {
  //       toast("Please upload KML / KMZ files only", {
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: false,
  //         progress: undefined,
  //         theme: "dark",
  //         transition: Bounce,
  //       });
  //     }
  //   }
  // };
  const onFileChange = async (event) => {
    const file = event.target.files;
    if (file.length > 0) {
      const name = file[0].name;
      const ext = name.split(".").pop();
      if (ext === "kml") {
        setShowUploadModal(false);
        setLoading(true);
        const formData = new FormData();
        formData.append("file", file[0]);
        const uploadUrl = "https://escan-systems.com/api/uploadFile";
        try {
          const response = await axios.post(uploadUrl, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          const fileURL = `https://escan-systems.com/backend/uploadedKML/${response.data}`;
          const respo = await axios.get(fileURL);
          const text = respo.data;
          const parser = new DOMParser();
          const kmlDocument = parser.parseFromString(text, "text/xml");
          const geojsonFile = toGeoJson.kml(kmlDocument);
          const geoJsonLayer = geojsonFile;
          if (
            geoJsonLayer.features.length > 1 &&
            geoJsonLayer.features[0].geometry.type !== "Polygon"
          ) {
            return;
          }
          const modifiedArray =
            geoJsonLayer.features[0].geometry.coordinates[0].map((innerArray) =>
              innerArray.slice(0, 2)
            );
          geoJsonLayer.features[0].geometry.coordinates = [modifiedArray];
          registerFarm(geoJsonLayer);
          setFarmLayer(geoJsonLayer);
          setKMLData(fileURL);
          setShowUploadModal(false);
          setLoading(false);
        } catch (error) {
          setLoading(false);

          toast("Something is not right, either your file has invalid bounds or has invalid geomtery", {
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
          });

          console.error("Error uploading file", error);
        }
      } else if (ext === "shp") {
        // Handle SHP file
      } else if (ext === "shx") {
        // Handle SHX file
      } else if (ext === "kmz") {
        // Handle KMZ file
      } else {
        toast("Please upload KML / KMZ files only", {
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "dark",
          transition: Bounce,
        });
      }
    }
  };
  useEffect(() => {

    
    const mapContainer = document.getElementById("map-container");
    if (mapContainer) {
      mapContainer.style.filter = loading ? "blur(1.8px)" : "none";
    }
  }, [loading]);
  const checkAvailable = () => {};
  const handleMapClick = (e) => {
    const newMarker = { lat: e.latlng.lat, lng: e.latlng.lng };
    console.log(newMarker)
    if (isMarkerMode) {
      const newMarker = { lat: e.latlng.lat, lng: e.latlng.lng };
      setMarker(newMarker);
      setIsMarkerMode(false); // Disable marker mode after placing the marker
    }
  };

  const MapClickHandler = () => {
    useMapEvents({
     // click: {handleMapClick}
     click: {}
    });
    return null;
  };


  const CustomMarkerIcon = L.divIcon({
    html: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="44px" height="44px" viewBox="0 0 44 44" version="1.1">
    <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com -->
    <title>Pin / Moving Task / Inactive</title>
    <desc>Created with Sketch.</desc>
    <defs>
        <path d="M22,3.5 C29.456174,3.5 35.5,9.55614087 35.5,17.0266 C35.5,18.5836753 35.2187504,20.2330998 34.6811011,21.9631 C33.6194847,25.3790752 31.600721,29.0060019 28.9036921,32.696583 C27.4706334,34.6575629 25.9361833,36.512512 24.4016033,38.2044218 C23.8642898,38.7968224 23.3651157,39.326756 22.9167503,39.7870307 C22.6467377,40.0642154 22.4529467,40.2572656 22.3480533,40.3589692 L22,40.6964385 L21.6519467,40.3589692 C21.5470533,40.2572656 21.3532623,40.0642154 21.0832497,39.7870307 C20.6348843,39.326756 20.1357102,38.7968224 19.5983967,38.2044218 C18.0638167,36.512512 16.5293666,34.6575629 15.0963079,32.696583 C12.399279,29.0060019 10.3805153,25.3790752 9.31889889,21.9631 C8.78124961,20.2330998 8.5,18.5836753 8.5,17.0266 C8.5,9.55614087 14.543826,3.5 22,3.5 Z" id="path-1"/>
        <filter x="-25.9%" y="-13.4%" width="151.9%" height="136.7%" filterUnits="objectBoundingBox" id="filter-2">
            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.4 0" type="matrix" in="shadowBlurOuter1"/>
        </filter>
    </defs>
    <g id="Pin-/-Moving-Task-/-Inactive" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="bound" fill-rule="nonzero" x="0" y="0" width="44" height="44"/>
        <g id="shape" fill-rule="nonzero">
            <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"/>
            <use fill="#FFFFFF" xlink:href="#path-1"/>
        </g>
        <path d="M22.2004372,39.0892518 C22.6407437,38.6372499 23.131804,38.1159301 23.6608967,37.5325932 C25.1731917,35.865253 26.6856166,34.0369296 28.0963079,32.106557 C30.7338671,28.4973535 32.7022572,24.9609283 33.7261547,21.6663219 C34.2355146,20.0273487 34.5,18.4762399 34.5,17.0266 C34.5,10.1079705 28.903433,4.5 22,4.5 C15.096567,4.5 9.5,10.1079705 9.5,17.0266 C9.5,18.4762399 9.76448541,20.0273487 10.2738453,21.6663219 C11.2977428,24.9609283 13.2661329,28.4973535 15.9036921,32.106557 C17.3143834,34.0369296 18.8268083,35.865253 20.3391033,37.5325932 C20.868196,38.1159301 21.3592563,38.6372499 21.7995628,39.0892518 C21.8722746,39.163895 21.9391764,39.2321399 22,39.2938331 C22.0608236,39.2321399 22.1277254,39.163895 22.2004372,39.0892518 Z" id="shape" fill="#877849" fill-rule="nonzero"/>
        <path d="M24,11 L25.5333333,12.5333333 L23.6066667,14.4466667 L24.5533333,15.3933333 L26.4666667,13.4666667 L28,15 L28,11 L24,11 Z M16,15 L17.5333333,13.4666667 L19.4466667,15.3933333 L20.3933333,14.4466667 L18.4666667,12.5333333 L20,11 L16,11 L16,15 Z M20,23 L18.4666667,21.4666667 L20.3933333,19.5533333 L19.4466667,18.6066667 L17.5333333,20.5333333 L16,19 L16,23 L20,23 Z M28,19 L26.4666667,20.5333333 L24.5533333,18.6066667 L23.6066667,19.5533333 L25.5333333,21.4666667 L24,23 L28,23 L28,19 Z" id="shape" fill="#FFFFFF" fill-rule="nonzero" transform="translate(22.000000, 17.000000) rotate(45.000000) translate(-22.000000, -17.000000) "/>
    </g>
</svg>`,
    className: 'custom-marker',
    iconSize: [32, 32],
    iconAnchor: [16, 32]
  });

  const [popupPosition, setPopupPosition] = useState(null);

  const handleMouseOver = (e) => {
    const { latlng } = e;
    setPopupPosition(latlng);
  };

 



  const [conversationStep, setConversationStep] = useState(0);


 
 
   
  const closeChat  = () =>{

 }



  return position !== null ? (
    <>
      <Title title={"MONITORING"} />
      <img src={ESS_LOGO} width={"250px"} style={{
            position: 'absolute',
            right: 0,
            zIndex: 999,
            opacity: 0.7,
        }} />
      {loading && <Loader />}

     
      <div style={{ height: "100vh" }}> 
          {/*  ,visibility:isHidden?'visible':'visible' position="absolute" bottom={16} right={16} sx={{ zIndex: 99999,width:'70%'}}  */}
        <motion.div
          drag
          dragElastic={0.2} // Controls the elasticity of the drag
          style={{ position: 'absolute', bottom: 16, right: 16, zIndex: 99999, width: '70%' }}
        >

          <Box   >

            {isOpen && (<BotView
              lat={centerCoordinates.lat.toFixed(4)}
              lng={centerCoordinates.lng.toFixed(4)}
              onClose={() => {
                setHidden(true)
                setIsOpen(false)
              }}

            />)}

            {showBotButton && (
              <motion.div

                animate={{
                  y: [0, -10, 0],
                }}
                transition={{
                  repeat: 3,
                  repeatType: "reverse",
                  duration: 2,
                  ease: "easeInOut"
                }}

              >


                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    padding: '5px',
                    borderRadius: '10px',
                    width: 'fit-content',
                    float: 'right',
                    color: 'white',
                    cursor: 'pointer',
                    '&:hover': {
                      transform: 'scale(1.3)',
                      transition: 'transform 0.2s ease',
                    },
                  }}


                  onClick={() => {
                    setHidden(false)
                    setIsOpen(true)
                    // var sound = new Howl({
                    //   src: ['https://escan-systems.com/popup.mp3'],
                    //   autoplay: true,
                    //   loop: false,
                    //   volume: 1,
                    //   html5: true,
                    //   onend: function () {
                    //     // console.log("SOUND FINISHED")

                    //     setIsOpen(true)
                    //   }
                    // });
                    // sound.play();


                  }}

                >
                  <img
                    src={"https://dmenjsnbha54b.cloudfront.net/images/full-bot.png"}
                    alt="ESS-BOT"
                    width={"55px"}
                  />

                </Box>

                {/* {
       isOpen?<>
       
       <Box
       sx={{
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         flexDirection: 'row', 
         padding: '10px',
         borderRadius: '10px',
         width: 'fit-content',
         background:'#28282b',
         color: 'white',
         cursor: 'pointer',
         '&:hover': {
           transform: 'scale(1.3)',
           transition: 'transform 0.2s ease',
         },
       }}


       onClick={() => {

         setHidden(true)
         setIsOpen(false)
       }}

     >
       <img
         src={"https://dmenjsnbha54b.cloudfront.net/images/cross-icon.svg"}
         alt="Close ESS BOT"
         width={"35px"}
       />

     </Box>
     
     </>:<>
      
       </>
     } */}



              </motion.div>
            )}








          </Box>

        </motion.div>


        <SplitPane
          split="vertical"
          sizes={!activeCompare ? [100, "0%", "auto"] : sizes}
          onChange={setSizes}
        >
          <Pane minSize={50} maxSize="80%">
         
       
            {/* {
              scoutingEnabled && (
                <Alert
                  severity="success"
                  style={{
                    position: "absolute",
                    top: "3%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    marginLeft: "0",
                    marginTop: "0", marginRight: 10,
                    marginBottom: "10px", zIndex: 1350, width: '100%'
                  }}
                  action={
                    <IconButton
                      size="small"
                      color="inherit"
                      onClick={() => { setScoutingEnabled(false) }}
                      style={{
                        position: "absolute",
                        top: "9px",
                        right: "2px",
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  }
                >
                  Scouting Mode Enabled, please move cursor to desired area and click 
                </Alert>
              )
            } */}

          
            <MapContainer
              center={position}
              zoomControl={true}
              zoom={7}
              scrollWheelZoom={true}
              style={{ minHeight: "100vh", minWidth: "100vw" }}
              whenCreated={setMap}
              whenReady={setMap}
              ref={mapRef}
              id="map-container"
              eventHandlers={{
                click: (e) => {
                  const newMarker = { lat: e.latlng.lat, lng: e.latlng.lng };
                  console.log("newMarker",newMarker)
                } 
              }}
              className={`${loading ? "map-container" : ""}`}
            >
         

              {/* <Zoning /> */}
              <MapClickHandler />
              {marker && <Marker position={[marker.lat, marker.lng]} icon={CustomMarkerIcon} draggable />}

              <TileLayer
                url="https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
                maxZoom={20}
                subdomains={["mt0", "mt1", "mt2", "mt3"]}
              />

              {bounds && (
                <>
                  <ImageOverlay
                    url={SentimageURL}
                    bounds={bounds}
                    interactive={true}
                    eventHandlers={{
                      mousemove: (e) => {
                        if(scoutingEnabled){
                          handleMouseOver(e)
                        }
                         
                      },
                      click:(e)=>{
                        if(scoutingEnabled){
                        
                          // setShowScoutingModal(true)
 
                        }  
                      }
                      
                    }} 
                  
                     
                    options={{
                      crossOrigin: "anonymous", 
                      interactive: true,
                      boundsOptions: {
                        paddingTopLeft: [0, 0],
                        paddingBottomRight: [0, 0],
                      },
                    }} 
                  />

                  
        
                </>
              )}
              {scoutingEnabled && popupPosition && (
                <Popup position={popupPosition}>
                  {/*  {popupPosition.lat.toFixed(4)}, {popupPosition.lng.toFixed(4)}   */}
                  <div>Drop a pin to add scouting task</div>
                </Popup>
              )}

              
              {zoningDrawing !== null && (
                <GeoJSON data={zoningDrawing} style={getFeatureStyle} />
              )}
              {kmlData && (
                <KMLLayer
                  kmlUrl={kmlData}
                  setBoundingBox={setBoundingBox}
                  setBounds={setBounds}
                  setCenterCoodinates={setCenterCoodinates}
                  getStats={getStats}
                  registerFarm={registerFarm}
                  getViews={getViews}
                  getViewsData={{
                    limit,
                    page,
                    startOfYear,
                    tillDate,
                  }}
                />
              )}

              <MapController />

              <LeafletgeoSearch />

              {/* <MapControls modal={AdvisoryModal} /> */}

              <Control position="topleft">
                <Box sx={{ display: "flex", flexDirection: "column" }}>

                

                <Tooltip placement="left" title="Draw Field">
                    <Button
                      onClick={() => {
                        let element = document.querySelector(
                              '[class="leaflet-buttons-control-button"]'
                            );

                            console.log("This is data", element);
                            element.click();
                      }}
                      variant="contained"
                      style={{
                        width: "35px", 
                        padding: "4px 2px 11px 3px",
                        marginTop: 12,
                        border:"none",
                        background: '#28282b',
                        color: 'white'  
                      }}
                    >
                      <PlaylistAdd style={{color:'#fff' ,marginLeft:1,marginBottom:1,padding:1}} />
                    </Button>

                    
                  </Tooltip>

                  
                  <Tooltip placement="left" title="Upload Field KML">
                    <Button
                      onClick={() => {
                        setShowUploadModal(true);
                      }}
                      variant="contained"
                      style={{ width: "35px", padding: "4px 2px 11px 3px" ,marginTop: 12,   border:"none",
                        background: '#28282b',
                        color: 'white'  }}
                    >
                      <UploadOutlined style={{color:'#fff',padding:1  }} />
                    </Button>
                  </Tooltip>

                  
                  <Tooltip placement="left" title="My Fields">
                  <Button
                      onClick={() => {
                        setShowForms((prev) => !prev);
                      }}
                      variant="contained"
                      style={{ width: "35px", padding: "4px 2px 11px 3px" ,marginTop: 12,   border:"none",
                        background: '#28282b',
                        color: 'white'  }}
                    >
                      <LayersIcon style={{color:'#fff',padding:1  }} />
                    </Button>
                  </Tooltip> 

                  

                  {bounds && (
                    <>

                      {/* <Tooltip placement="left" title="Add Scouting Tasks">
                        <Button
                          onClick={() => {
                            setScoutingEnabled(!scoutingEnabled)
                          }}
                          variant="contained"
                          style={{
                            width: "35px",
                            padding: "4px 2px 11px 3px",
                            marginTop: 12,
                            border: "none",
                            background: '#28282b',
                            color: 'white'
                          }}
                        >
                          <AdjustIcon style={{ color: '#fff', marginLeft: 1, marginBottom: 1, padding: 1 }} />
                        </Button>


                      </Tooltip> */}

                    </>
                  )}   


                  <Tooltip placement="left" title="Comparison View">
                    <Button
                      onClick={() => {
                        console.log(
                          "length",
                          farmLayer,
                          centerCoordinates.lat,
                          centerCoordinates.lng
                        );
                        // setActiveCompare(!activeCompare)
                        if (farmLayer != "") {
                          setActiveCompare(!activeCompare);
                        } else {
                          toast(
                            "Please draw field and select desired index in order to view comparison ",
                            {
                              autoClose: 5000,
                              hideProgressBar: true,
                              closeOnClick: true,
                              pauseOnHover: false,
                              draggable: false,
                              progress: undefined,
                              theme: "dark",
                              transition: Bounce,
                            }
                          );
                        }
                      }}
                      variant="contained"
                      style={{
                        width: "35px",
                        padding: "4px 2px 11px 3px",
                        marginTop: 12, border: "none",
                        background: '#28282b',
                        color: 'white'
                      }}
                    >


                      <SplitscreenOutlined style={{ color: '#fff', padding: 2, transform: 'rotate(90deg)' }} />
                    </Button>
                  </Tooltip>

 
                  {/* <Tooltip placement="left" title="Scouting Tasks">
                    <Button
                      onClick={() => {
                        setIsMarkerMode(!isMarkerMode);

                      }}
                      variant="contained"
                      style={{
                        width: "35px",
                        padding: "4px 2px 11px 3px",
                        marginTop: 12,
                      }}
                    >
                      <AddLocationAltOutlined style={{color:'#111418' }} />
                    </Button>
                  </Tooltip> */}




                  <Tooltip placement="left" title="Logout">
                    <Button
                      onClick={() => {
                        logoutAuth();
                      }}
                      variant="contained"
                      style={{
                        width: "35px",
                        padding: "4px 2px 11px 3px",
                        marginTop: 12,  border:"none",
                        background: '#28282b',
                        color: 'white'  
                      }}
                    >
                      <PowerSettingsNewOutlined style={{color:'#fff',padding:2 }} />
                    </Button>
                  </Tooltip>

                

                </Box>
              </Control>

              {/* <Control position="topleft">
            <Tooltip placement="left" title="Add new Field / Farm to Monitor">
              <Fab 
              onClick={()=>{
                const divElements = document.getElementsByClassName('button-container'); // Note the plural "Elements"
                for (const element of divElements) { 
                  if (element.title === 'Draw Polygons'  ) { 
                    element.classList.add('active');
                  }
                } 
              }}
              variant="extended" style={{borderRadius:8,backgroundColor:'#28282b',color:'white'}}>
                <Add sx={{ mr: 1 }} 
                className='customFab'
                />
  
                
                Add Field
              </Fab>
            
            </Tooltip>
          </Control>   */}

              {showForms && <Farms  isVisible={showForms} onGeoJsonClick={() => { setShowForms(false) }} onClose={() => setShowForms(false)}
                setBoundingBox={setBoundingBox}
                setBounds={setBounds}
                setFarmNumber={setFarmID}
                setFarmAr={setFarmArea}
                setCenterCoodinates={setCenterCoodinates}
                getStats={getStats}
                registerFarm={registerFarm}
                getViews={getViews}
                getViewsData={{
                  limit,
                  page,
                  startOfYear,
                  tillDate,
                }}
              />   }
          {/* {
            showForm && <CWR
            fieldData={fieldData}
            isVisible={showCwr}
            dateStart={startOfYear}
            dateEnd={tillDate}
            center={centerCoordinates}
            onCloseClick={updateCwrState}
          />
          } */}
              {/* {showForms && <Farms   isVisible={showForms} onGeoJsonClick={() => { setShowForms(false) }} onClose={() => setShowForms(false)}
                setBoundingBox={setBoundingBox}
                setBounds={setBounds}
                setCenterCoodinates={setCenterCoodinates}
                getStats={getStats}
                registerFarm={registerFarm}
                getViews={getViews}
                getViewsData={{
                  limit,
                  page,
                  startOfYear,
                  tillDate,
                }}
              />} */}

            </MapContainer>
           

            {showScoutingModal && (
              <ScoutingUpdate
                centerCoordinates={popupPosition} 
                bounds={bounds}
                farmID={farmID}
                farmArea={farmArea} 
                showUpdateFieldModal={showScoutingModal}
                setShowUpdateFieldModal={setShowScoutingModal}
              />
            )}


            {showUpdateFieldModal && (
              <UpdateField
                centerCoordinates={centerCoordinates}
                SentimageURL={SentimageURL}
                bounds={bounds}
                farmID={farmID}
                farmArea={farmArea}
                setFieldData={setFieldData}
                showUpdateFieldModal={showUpdateFieldModal}
                setShowUpdateFieldModal={setShowUpdateFieldModal}
              />
            )}

            {showZoneNumberModal && (
              <ZoneNumberModal
                handleVegetationCase={handleVegetationCase}
                indices={indices}
                setNumberOfZones={setNumberOfZones}
                showZoneNumberModal={showZoneNumberModal}
                setShowZoneNumberModal={setShowZoneNumberModal}
              />
            )}

            <Dialog
              open={showUploadModal}
              onClose={() => setShowUploadModal(false)}
            >
              <Box
                sx={{
                  width: "600px",
                  bgcolor: "#28282b",
                  boxShadow: 24,
                  color: "white",
                }}
              >
                <Box
                  style={{
                    margin: "5px",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    boxShadow: "0px 4px 4px -2px rgba(0,0,0,0.2)",
                    width: "100%",
                    padding: "5px",
                  }}
                >
                  <div  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: "0 15px",
                  }}>
                  <Typography variant="h6">
                    Select the field adding option
                  </Typography>
                  <CloseIcon
                    color={"white"}
                    onClick={() => setShowUploadModal(false)}
                    style={{ cursor: "pointer" }}
                  />
                  </div>
                 

                </Box>
                <DialogContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      mt: 2,
                    }}
                  >
                    <Box
                      sx={{
                        width: "45%",
                        backgroundColor: "#0e0d09",
                        p: 2,
                        borderRadius: "5px",
                        textAlign: "center",
                        cursor: "pointer",
                        border: "1px dotted black",
                      }}
                      onClick={() => {
                        /* handle upload field */
                      }}
                    >
                      <img src={UPLOAD_FILE} height={'100px'} />
                      <Typography variant="body1">Upload fields</Typography>
                      <Typography variant="body2" style={{ color: "grey" }}>
                        Select files with the field contours for the upload to
                        start
                      </Typography>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={onFileChange}
                      />
                      <Box sx={{ mt: 7 }}>
                        <Button
                          style={{
                            background:'#fff',
                            color: "#000",
                            border: "1px dotted rgb(115, 100, 60)",
                          }}
                          onClick={() => fileInputRef.current.click()}
                        >
                          Select Files
                        </Button>
                      </Box>
                      <Typography
                        variant="body2"
                        sx={{ mt: 1 }}
                        style={{ color: "grey" }}
                      >
                        Supported formats are: .kml
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "45%",
                        backgroundColor: "#21293000",
                        p: 2,
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        /* handle upload field */
                      }}
                    >
                      <img src={DRAW_FIELD} height={'100px'}/>
                      <Typography variant="body1">Draw field on map</Typography>
                      <Typography variant="body2" style={{ color: "grey" }}>
                        Draw your field on map, shaping polygn with drawing tool
                      </Typography>
                      <Box sx={{ mt: 7 }}>
                        <Button
                          style={{
                            background: "rgb(115, 100, 60)",
                            color: "white",
                            border: "1px dotted rgb(115, 100, 60)",
                          }}
                          onClick={() => {
                            setShowUploadModal(false);
                            let element = document.querySelector(
                              '[class="leaflet-buttons-control-button"]'
                            );

                            console.log("This is data", element);
                            element.click();
                          }}
                        >
                          Draw Field
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </DialogContent>
              </Box>
            </Dialog>

            {dateArray.length > 0 ? (
              <>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: legendComponentPosition,
                    right: "2%",
                    zIndex: 1000,
                  }}
                >
                  {zoningDrawing !== null ? (
                    <LegendComponent
                      selectedIndex={"Vegetation"}
                      zoningDrawing={zoningDrawing}
                    />
                  ) : (
                    <LegendComponent selectedIndex={indices} />
                  )}
                </Box>
                <Grid container style={floatingLayerStyles}>
                  {/* Grid Component */}
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    md={12}
                    sm={12}
                    style={{ marginTop: "10px", backgroundColor: "#28282B" }}
                  >
                    <DateCard
                      dateStart={startOfYear}
                      dateEnd={tillDate}
                      center={centerCoordinates}
                      farm={farmArea}
                      dataArray={dateArray}
                      onCwrClick={updateCwrState}
                      onAnalyticsClick={updateAnalyticsState} 
                      selectedIndex={indices}
                      onViewIDClick={getViewIDfromDate}
                      dateRangeUpdate={dateRangeUpdate}
                      onIndexChange={handleChange}
                      onZoningChange={_onZoningChange}
                      setShowUpdateFieldModal={setShowUpdateFieldModal}
                      setLegendComponentPosition={setLegendComponentPosition}
                      layer={farmLayer}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                {farmArea && (
                  <Oval
                    visible={true}
                    height="50"
                    radius="50"
                    width="50"
                    color="#fff"
                    secondaryColor="#cccc"
                    ariaLabel="revolving-dot-loading"
                    wrapperStyle={{
                      position: "absolute",
                      top: "95%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      zIndex: 99999,
                    }}
                    wrapperClass=""
                  />
                )}
              </>
            )}
          </Pane>
          <div style={{ ...layoutCSS }}>
            <MapContainer
              center={position}
            
              zoomControl={true}
              zoom={7}
              scrollWheelZoom={true}
              whenReady={checkAvailable}
              style={{ minHeight: "100vh", minWidth: "100vw" }}
              ref={mapRef}
              id="map-container"
            >
              {centerCoordinates && (
                <LocationFound coordinates={centerCoordinates} />
              )}

              {/* 

              Do not delete
              const VIEW_ID = "S2/21/H/UC/2020/12/9/0";
              const BAND = "B01,B03,B04";
              const API_KEY = "apk.b2a5fffb511b4afde659b8b62b1f2701d215b9f688e26a0a5c11520024cbb0cf";
              const API = "https://gate.eos.com/api/render";

              mapboxgl.accessToken = 'pk.eyJ1IjoiYWdyb2Zha2UiLCJhIjoiY2toMzlmYmx0MG1sODMwbHNxMjcxZHY0ciJ9.KXG7HeFRGFZ26wMXtG27Dw';
              tiles: [`${API}/${VIEW_ID}/${BAND}/{z}/{x}/{y}?api_key=${API_KEY}`],

            */}

              <TileLayer
                url="https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
                maxZoom={20}
                subdomains={["mt0", "mt1", "mt2", "mt3"]}
              />

              {bounds && (
                <ImageOverlay
                  url={SentimageURLSec}
                  bounds={bounds}
                  interactive={true}
                  eventHandlers={{
                    mousemove: (e) => {
                      console.log('mouse aya clicked!',e)
                    } 
                  }}
                  position={centerCoordinates}
                  options={{
                    crossOrigin: "anonymous",
                    interactive: true,
                    boundsOptions: {
                      paddingTopLeft: [0, 0],
                      paddingBottomRight: [0, 0],
                    },
                  }}
                />
              )}
              {zoningDrawingSec !== null && (
                <GeoJSON data={zoningDrawingSec} style={getFeatureStyleSec} />
              )}
            </MapContainer>
            {showZoneNumberModalSec && (
              <ZoneNumberModal
                handleVegetationCase={handleVegetationCaseSec}
                indices={secondaryIndices}
                setNumberOfZones={setNumberOfZonesSec}
                showZoneNumberModal={showZoneNumberModalSec}
                setShowZoneNumberModal={setShowZoneNumberModalSec}
              />
            )}
            {dateArray.length > 0 ? (
              <>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: legendComponentPositionSec,
                    right: "2%",
                    zIndex: 1000,
                  }}
                >
                  {zoningDrawingSec !== null ? (
                    <LegendComponent
                      selectedIndex={"Vegetation"}
                      zoningDrawing={zoningDrawingSec}
                    />
                  ) : (
                    <LegendComponent selectedIndex={secondaryIndices} />
                  )}
                </Box>
                <Grid container style={floatingLayerStyles}>
                  {/* Grid Component */}
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    md={12}
                    sm={12}
                    style={{ marginTop: "10px", backgroundColor: "#28282B" }}
                  >
                    <SDateCard
                      dateStart={startOfYear}
                      dateEnd={tillDate}
                      center={centerCoordinates}
                      farm={farmArea}
                      dataArray={dateArray}
                      selectedIndex={secondaryIndices}
                      onViewIDClick={getSViewIDfromDate}
                      dateRangeUpdate={dateRangeUpdate}
                      onIndexChange={handleSecondaryIndicesChange}
                      onZoningChange={_onZoningChangeSec}
                      setLegendComponentPosition={setLegendComponentPositionSec}
                      layer={farmLayer}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                {farmArea && (
                  <Oval
                    visible={true}
                    height="50"
                    radius="50"
                    width="50"
                    color="#fff"
                    secondaryColor="#cccc"
                    ariaLabel="revolving-dot-loading"
                    wrapperStyle={{
                      position: "absolute",
                      top: "95%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      zIndex: 99999,
                    }}
                    wrapperClass=""
                  />
                )}
              </>
            )}
          </div>
        </SplitPane>
      </div>

      <ToastContainer />
      {showCwr ? (
        <>
          <CWR
            fieldData={fieldData}
            isVisible={showCwr}
            dateStart={startOfYear}
            dateEnd={tillDate}
            center={centerCoordinates}
            onCloseClick={updateCwrState}
          />
        </>
      ) : (
        <></>
      )}

      {showAnalytics ? (
        <>
          <WeatherAnalytics
            fieldData={fieldData}
            isVisible={showAnalytics}
            dateStart={"2024-11-01"}
            dateEnd={tillDate}
            center={centerCoordinates}
            onCloseClick={updateAnalyticsState}
          />
        </>
      ) : (
        <></>
      )}


{/* 
      {isOpen ? (
        <>
          <BotView
            fieldData={fieldData}
            isVisible={isOpen}
            dateStart={"2024-11-01"}
            dateEnd={tillDate}
            center={centerCoordinates}
            onCloseClick={()=>{setIsOpen(false)}}
          />
        </>
      ) : (
        <></>
      )} */}




      <Tour
        backdrop={"static"}
        steps={steps}
        onRequestClose={() => setIsTourOpen(false)}
        closeWithMask={false}
        isOpen={false}
      /> 

    
       

    </>
  ) : (
    <CustomLoader />
  );
}

export const AdvisoryModal = NiceModal.create(({ location }) => {
  const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  };

  const modal = useModal();
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const handleFormSubmit = (condition) => {
    if (condition) {
      setMessage("Message sent successfully");
      setSeverity("success");
    } else {
      setMessage("Message sent failed.Please try again after 1 minutes");
      setSeverity("error");
    }
    setFormSubmitted(true);
  };
  useEffect(() => {



    if (isFormSubmitted) {
      const timeout = setTimeout(() => {
        setFormSubmitted(false);
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, []);

  const controls = useAnimation();
  const bounce = async () => {
    await controls.start({ y: -10, transition: { type: 'spring', stiffness: 500 } });
    await controls.start({ y: 0, transition: { type: 'spring', stiffness: 500 } });
  };

 // const intervalId = setInterval(bounce, 3000); // Bounce every 3 seconds
 

  //console.log("KML",location)
  return (
    <Modal
      open={modal.visible}
      okButtonProps={{ style: { display: "none" } }}
      cancelText="Close"
      title={" "}
      width={1300}
      onCancel={modal.hide}
      afterClose={modal.remove}
    >
      <ContactForm
        onFormSubmit={handleFormSubmit}
        boxShadow={false}
        location={location}
        area={"na"}
      />

      {isFormSubmitted && (
        <Alert
          severity={severity}
          role="presentation"
          sx={{
            width: {
              lg: "18%",
            },
            position: "fixed",
            bottom: "5%",
            left: "2%",
            zIndex: 1,
            animation: "fade-out 5s forwards",
          }}
        >
          {message}
        </Alert>
      )}
    </Modal>
  );
});

export default MapView;
