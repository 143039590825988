import Toolbar from "@mui/material/Toolbar";
import EARTH_IMAGE from "../../public/images/earth.png"
import { Box, Typography, Grid } from "@mui/material";

import { useWindowSize } from "@uidotdev/usehooks";
import { ASSET_IMAGE } from "../utils/constants";
export default function HomeComponent1() {
  const windowSize = useWindowSize();
  return (
    <div id="home">
          <Grid container component="main">
      <Toolbar />
      <Toolbar />
      <Grid item xs={12} style={{ backgroundColor: "#11151b" }} >
        <Box
          style={{
            margin: "0 auto",
            marginTop: "40px",
            marginBottom: "40px",
            width:
              windowSize.width < 992
                ? "100%"
                : windowSize.width < 1025
                ? "85%"
                : windowSize.width < 1960
                ? "65%"
                : "50%",
            display: "flex",
            flexDirection: windowSize.width < 992 ? "column-reverse" : "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box style={{ width: windowSize.width < 992 ? "95%" : "50%" }}>
            <Typography
              variant="h4"
              component="div"
              color={"white"}
              mb={3}
              style={{ fontWeight: "bold" }}
            >
              <strong>
                Earth Scan System:{" "}
                <span style={{ color: "rgb(242 126 48)" }}>
                  One stop solution for Sustainable Agriculture and Natural Resouce Management {" "}
                </span>
              </strong>
            </Typography>
            <Typography
              variant="h6"
              component="div"
              color={"white"}
              mb={3}
              style={{ fontWeight: "bold" }}
            >
              <strong>
                ESS aims to create a beneficial impact by leveraging geospatial data alongside tailored algorithms.
              </strong>
            </Typography>
            <Typography
              variant="body1"
              component="div"
              style={{ color: "#c1cfdb" }}
            >
              At ESS, we use cutting-edge remote sensing technologies to deliver comprehensive Earth solutions that empower industries, governments, and communities to make informed decisions based on accurate, real-time environmental data. Our innovative approach combines advanced satellite imagery, aerial data collection, and sophisticated bigdata analytics to provide unparalleled insights into Earth's natural and human-made environments.
            </Typography>
          </Box>
          <Box style={{ width: windowSize.width < 992 ? "100%" : "50%" }}>
            <img
              src={`${ASSET_IMAGE}/earth.png`}
              width="100%"
              alt="earth"      
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
    </div>
  );
}
